import React from "react"
import withPreview, { getContent } from "../apollo/preview"
import { getVenueHireThemeDetailPagePreview as PREVIEW_QUERY } from "../preview-queries/venueHireThemeDetailPreview"

// Data
import { graphql } from "gatsby"

// Content
import siteContent from "../content/siteContent"

// Components
import Layout from "../components/Global/Layout"
import Main from "../components/Shared/Main"
import Article from "../components/Shared/Article"
import LargeLandingHighlight from "../components/Shared/LargeLandingHighlight"
import Leader from "../components/Shared/Leader"
import LeaderList from "../components/Shared/LeaderList"
import SectionTitle from "../components/Shared/SectionTitle"
import HeroAreaLarge from "../components/Shared/HeroAreaLarge"
import SectionBlurb from "../components/Shared/SectionBlurb"
import LargeTextCTA from "../components/Shared/LargeTextCTA"
import VenueHireVenueLeader from "../components/VenueHire/VenueHireVenueLeader"
import VenueHireVenueLeaderList from "../components/VenueHire/VenueHireVenueLeaderList"
import { venueForPage } from "../helpers/venueForPage"
import ContentWithSidebar from "../components/Shared/ContentWithSidebar"
import Content from "../components/Shared/Content"
import ArticleHeader from "../components/Shared/ArticleHeader"
import SessionLeaderList from "../components/Sessions/SessionLeaderList"
import SessionLeader from "../components/Sessions/SessionLeader"
import Sidebar from "../components/Shared/Sidebar"
import Subnavigation from "../components/Shared/Subnavigation"
import CTA from "../components/Shared/CTA"
import PersonCTA from "../components/Shared/PersonCTA"
import VenueHireFooterCTA from "../components/Shared/VenueHireFooterCTA"
import SEOSettings from "../components/Shared/SEOSettings"

const VenueHireThemeDetailPage = (props) => {
    const page = getContent(props, "page")
    const venue = venueForPage({ page: page, menus: props.data.menus })
    const superTitle = page.ancestors.nodes && page.ancestors.nodes.slice(-1)[0].title

    return (
        <Layout venue={venue} currentLocation={props.path} title={page.title}>
            <SEOSettings entity={page} />
            <HeroAreaLarge superTitle={superTitle} title={page.title} introduction={page.page_introduction.introduction} image={page.image.image} />

            <Main>
                <SectionTitle>{page.sub_title?.subTitle}</SectionTitle>

                <VenueHireVenueLeaderList>
                    {page.venue_hire_theme_fields.venues &&
                        page.venue_hire_theme_fields.venues.map((venue) => <VenueHireVenueLeader key={venue.venue.id} {...venue} />)}
                </VenueHireVenueLeaderList>

                {page.footer_cta_options && page.footer_cta_options.showPeople && <VenueHireFooterCTA {...page.footer_cta_options} />}
            </Main>
        </Layout>
    )
}

export const pageQuery = graphql`
    query VenueHireThemeDetailPageQuery($databaseId: Int!) {
        menus: allWpMenu {
            results: nodes {
                ...PageMenusNode
            }
        }

        page: wpPage(databaseId: { eq: $databaseId }) {
            ...PageContent
            ...VenueThemeDetails
            ...FooterCTASettings
        }

        #wpgraphql {
        #    ...PageMenus
        #    page(id: $databaseId, idType: DATABASE_ID) {
        #        ...PageContent
        #        ...VenueThemeDetails
        #        ...FooterCTASettings
        #    }
        #}
    }
`

export default withPreview({ preview: PREVIEW_QUERY })(VenueHireThemeDetailPage)
