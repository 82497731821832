import React from "react"

import * as styles from "./CTA.module.scss"
import PlaceholderImage from "./PlaceHolderImage"
import Image from "./Image"
import { imageSizes } from "../../helpers/imgix"
import { decode } from "../../helpers/stringHelpers"

const CTA = (props) => (
    <div className={styles.CTA}>
        <a href={props.cta_fields?.link.url}>
            <div>
                {props.cta_fields?.image && <Image url={props.cta_fields?.image.sourceUrl} alt={props.cta_fields?.image.title} size={imageSizes.default} />}
            </div>
            <div className={styles.textContent}>
                <h3>{decode(props.title)}</h3>
                {props.cta_fields?.description && <div className={styles.description}>{decode(props.cta_fields?.description)}</div>}
            </div>
        </a>
    </div>
)

export default CTA
