import React, { useEffect } from "react"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { detect } from "detect-browser"

// Styles
import * as styles from "./VenueHireVenueLeader.module.scss"

// Components
import PlaceholderImage from "../Shared/PlaceHolderImage"
import Image from "../Shared/Image"
import { imageSizes } from "../../helpers/imgix"

const textContentVariants = {
    visible: {
        opacity: 1,
        x: 0,
        transition: { delay: 0.5, duration: 0.3, ease: "easeOut" },
    },
    hidden: { opacity: 0, x: 50 },
}

// Unset initial transition state for IE
const isIeBrowser = /(ie)/i.test(detect().name)
if (isIeBrowser) {
    textContentVariants.hidden.opacity = 1
    textContentVariants.hidden.x = 0
}

const VenueHireVenueLeader = (props) => {
    const controls = useAnimation()
    const [ref, inView] = useInView()
    useEffect(() => {
        if (inView) {
            controls.start("visible")
        }
    }, [controls, inView])

    return (
        <div className={styles.VenueHireVenueLeader}>
            <div
                className={styles.logo}
                style={{
                    backgroundColor: props.venue.venue_fields.logoBackgroundColour,
                }}
            >
                <Image url={props.venue.venue_fields.logo.sourceUrl} sizes={imageSizes.logo} />
            </div>
            <div className={styles.image}>{props.image && <Image url={props.image.sourceUrl} alt={props.image.title} sizes={imageSizes.hero} />}</div>
            <motion.div className={styles.textContent} ref={ref} initial={typeof(window) !== 'object' ? 'visible' : 'hidden'} animate={controls} variants={textContentVariants}>
                <h3 dangerouslySetInnerHTML={{ __html: props.title }}></h3>
                <div className={styles.description} dangerouslySetInnerHTML={{ __html: props.description }}></div>
                <ul>
                    <li>
                        <a href={props.link ? props.link.url : props.venue.venue_fields.venueHomepage.link}>{props.linkText}</a>
                    </li>
                </ul>
            </motion.div>
        </div>
    )
}

export default VenueHireVenueLeader
