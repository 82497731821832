import React from "react"

import * as styles from "./SessionLeader.module.scss"
// import PlaceholderImage from "../Shared/PlaceHolderImage";

const SessionLeader = (props) => (
    <div className={styles.SessionLeader}>
        <a href={props.link.url}>
            {props.title && <h2 dangerouslySetInnerHTML={{ __html: props.title }}></h2>}

            {props.venue && <span className={styles.venue} dangerouslySetInnerHTML={{ __html: props.venue.title }}></span>}

            {props.subject && <span className={styles.subject} dangerouslySetInnerHTML={{ __html: props.subject }}></span>}

            {props.description && <div dangerouslySetInnerHTML={{ __html: props.description }}></div>}
        </a>
    </div>
)

export default SessionLeader
