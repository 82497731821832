import React from "react"

import * as styles from "./VenueHireVenueLeaderList.module.scss"

const VenueHireVenueLeaderList = ({children}) => (

    <div className={styles.VenueHireVenueLeaderList}>
        {children}
    </div>

)

export default VenueHireVenueLeaderList